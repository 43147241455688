<template lang="pug">
fi-modal.loan-send-message-modal(
  ref     = 'modal'
  v-model = 'showModal'
  :title  = '$t(title)'
  size    = 'lg'
  lazy
  @shown  = 'resetData'
  @hide   = 'onHide'
)
  form.form-horizontal(@submit.prevent = 'onSubmit')
    fi-form-field(
      v-if        = '!isSendContract'
      :label      = '$t("channel") '
      :label-cols = 3
    )
      fi-select(
        v-model  = '$v.formData.channel.$model'
        :options = 'channels'
        required
        sm
      )
    loan-message-form-fields(
      :form-data         = '$v.formData'
      :templates         = 'optionsFromTemplates([type])'
      :invoices          = 'allInvoices'
      :contract          = 'contract'
      :store-module      = 'storeModule'
      :email-options     = 'customerEmailOptions'
      :phone-options     = 'customerPhoneOptions'
      :categories        = 'categories'
      :recipient-options = 'recipientOptions'
      :third-parties     = 'thirdParties'
      :storeType         = 'storeModule'
      :languages         = 'languages'
      :schedule-id        = 'schedule ? schedule.id : " "'
      @search            = 'findSystemCustomers'
      @template-change   = 'getMessageTemplate'
      @select-party      = 'setSelectedThirdParty'
    )
    fi-form-field(
      v-if = '!isSendContract'
      :label      = ' $t("Invoice template") '
      :label-cols = 3
    )
      fi-select.col-11(
        v-model = 'invoiceTemplateModel'
        :options       = 'optionsFromTemplates(["InvoicePdfTemplate"])'
        sm
      )
    fi-form-field(
      v-if = '!isSendContract'
      :label      = ' $t("Invoice date") '
      :label-cols = 3
    )
      fi-select.col-11(
        v-model = 'invoiceModel'
        :options       = 'sortInvoice'
        sm
      )
      label.col-form-label-sm.col-auto(v-if = 'invoiceTemplateModel && invoiceModel')
        a(
          href           = '#'
          @click.prevent = 'previewInvoicePdf'
        )
          i.fa.fa-print
    template(v-if = 'isSendContract')
      fi-form-field.mt-1(
        v-if        = 'scheduleOptions.length'
        :label      = '$t("selectedSchedule")'
        :label-cols = 3
      )
        fi-select(
          v-model.number = '$v.formData.scheduleId.$model'
          :options       = 'scheduleOptions'
          :disabled      = '!scheduleOptions'
          required
          sm
        )
      fi-form-field.mt-1(
        :label      = '$t("agreement")'
        :label-cols = 3
      )
        fi-select.col-11(
          v-model.number = '$v.formData.agreementTemplateId.$model'
          :disabled      = '!product.agreementTemplateIdOverride && !!product.agreementTemplateId'
          :options       = 'optionsFromTemplates(["AgreementPdfTemplate"])'
          sm
        )
        label.col-form-label-sm.col-auto(v-if = '$v.formData.agreementTemplateId.$model')
          a(
            href           = '#'
            @click.prevent = 'previewAgreementTemplatePdf'
          )
            i.fa.fa-spinner.fa-pulse.ml-1(v-if = 'loadingAgreementTemplatePreview')
            i.fa.fa-print(v-else)
      fi-form-field.mt-1(
        v-if        = 'scheduleOptions.length'
        :label      = '$t("schedule")'
        :label-cols = 3
      )
        fi-select.col-11(
          v-model.number = '$v.formData.scheduleTemplateId.$model'
          :disabled      = '!product.scheduleTemplateIdOverride && !!product.scheduleTemplateId'
          :options       = 'optionsFromTemplates(["SchedulePdfTemplate"])'
          sm
        )
        label.col-form-label-sm.col-auto(v-if = '$v.formData.scheduleTemplateId.$model')
          a(
            href           = '#'
            @click.prevent = 'previewScheduleTemplatePdf'
          )
            i.fa.fa-spinner.fa-pulse.ml-1(v-if = 'loadingScheduleTemplatePreview')
            i.fa.fa-print(v-else)

      fi-form-field.mt-1(
        :label      = '$t("infoSheet")'
        :label-cols = 3
      )
        fi-select.col-11(
          v-model.number = '$v.formData.infoSheetTemplateId.$model'
          :disabled      = '!product.informationSheetTemplateIdOverride && !!product.informationSheetTemplateId'
          :options       = 'optionsFromTemplates(["InfoSheetPdfTemplate"])'
          sm
        )
        label.col-form-label-sm.col-auto(v-if = '$v.formData.infoSheetTemplateId.$model')
          a(
            href           = '#'
            @click.prevent = 'previewInfosheetTemplatePdf'
          )
            i.fa.fa-spinner.fa-pulse.ml-1(v-if = 'loadingInfosheetTemplatePreview')
            i.fa.fa-print(v-else)

  template(#modal-footer)
    button.btn.btn-primary(
      :disabled      = 'saving'
      @click.prevent = 'onSubmit'
    )
      | {{ $t('common:send') }}
      i.fa.fa-spinner.fa-pulse.ml-1(v-if = 'saving')
    button.btn.btn-secondary(
      :disabled      = 'saving'
      @click.prevent = '$refs.modal.hide()'
    ) {{ $t('common:cancel') }}
</template>


<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { validation, validators } from '@/validation'
import FiFormField from '@/components/FiFormField'
import FiSelect from '@/components/FiSelect'
import LoanMessageFormFields from '@/views/loans/LoanMessageFormFields'
import FiModal from '@/components/FiModal'
import { findCustomerMainContact } from '@/helpers'

export default {
  name: 'loan-send-message-modal',

  components: {
    LoanMessageFormFields,
    FiFormField,
    FiSelect,
    FiModal
  },

  mixins: [validation],

  props: {
    value: {
      type: Boolean,
      default: false
    },
    storeModule: {
      type: String,
      default: 'loans'
    },
    contract: {
      type: Object,
      required: true
    },
    product: {
      type: Object,
      required: true
    },
    type: {
      type: String,
      required: true
    },
    customer: {
      type: Object,
      default: () => ({})
    },
    schedules: {
      type: Array,
      required: true
    },
    schedule: {
      type: Object,
      default: null
    },
    thirdParties: {
      type: Array,
      required: false,
      default: null
    }
  },

  i18nOptions: {},

  data () {
    return {
      selectedThirdParty: '',
      formData: {
        channel: 'email',
        languageId: '',
        subject: '',
        text: '',
        recipients: null,
        invoiceTemplateId: '',
        infoSheetTemplateId: '',
        agreementTemplateId: '',
        scheduleTemplateId: '',
        templateId: '',
        files: [],
        scheduleId: '',
        documentCategoryId: '',
        customerEmail: null,
        customerPhoneNumber: null,
        invoiceDate: ''
      }
    }
  },

  computed: {
    ...mapGetters('classifiers', ['optionsFromClassifier', 'classifierByName', 'classifierList']),
    ...mapGetters('templates', ['optionsFromTemplates']),
    ...mapGetters('settings', ['settingByKey']),
    ...mapState('templates', ['allInvoices']),
    ...mapState({
      systemCustomersForSelect (state, getters) {
        return getters[`${this.storeModule}/systemCustomersForSelect`]
      },
      messageTemplate (state) {
        return state[this.storeModule].messageTemplate
      }
    }),
    showModal: {
      get () {
        return this.value
      },
      set (newValue) {
        this.$emit('input', newValue)
      }
    },
    invoiceModel: {
      get () {
        return this.formData.invoiceDate
      },
      set (invoiceDate) {
        this.formData.invoiceDate = invoiceDate
      }
    },
    invoiceTemplateModel: {
      get () {
        return this.formData.invoiceTemplateId
      },
      set (invoiceTemplateId) {
        this.formData.invoiceTemplateId = invoiceTemplateId
      }
    },
    sortInvoice () {
      const list = this.allInvoices
      return list
    },
    saving () {
      return this.$vueLoading.isLoading('contract:message:save')
    },
    loadingAgreementTemplatePreview () {
      return this.$vueLoading.isLoading(`contract:template:${this.formData.agreementTemplateId}:fetch`)
    },
    loadingInfosheetTemplatePreview () {
      return this.$vueLoading.isLoading(`contract:template:${this.formData.infoSheetTemplateId}:fetch`)
    },
    loadingScheduleTemplatePreview () {
      return this.$vueLoading.isLoading(`contract:template:${this.formData.scheduleTemplateId}:fetch`)
    },
    scheduleOptions () {
      return this.schedules.map((schedule) => ({
        value: schedule ? schedule.id : '',
        text: `${this.classifierByName(schedule.status.classifier, schedule.status.id)?.human} ` + (schedule.validFrom ? `(${schedule.validFrom})` : '')
      }))
    },
    isSendContract () {
      return /contract/ig.test(this.type)
    },
    channels () {
      return this.optionsFromClassifier('documentDeliveryChannels', true).filter(({ value }) => value !== 'call')
    },
    categories () {
      return this.optionsFromClassifier('DocumentCategoryType')
    },
    languages () {
      return Object.values(this.classifierList('languages'))
        .filter(lang => this.settingByKey('supportedTemplateLanguages').includes(lang.code))
        .map(({ human, id }) => ({ text: human, value: id }))
    },
    title () {
      return this.isSendContract ? 'contractTitle' : 'notificationTitle'
    },
    customerEmailOptions () {
      return this.customer?.emails?.map(({ value }) => value)
    },
    customerPhoneOptions () {
      return this.customer?.phoneNumbers?.map(({ value }) => value)
    },
    isEmailChannel () {
      return /email/i.test(this.formData.channel)
    },
    recipientOptions () {
      const { customerCoBorrower, customerWarranter } = this.customer ?? {}
      return [
        ...[
          customerCoBorrower,
          customerWarranter
        ].filter(item => item)
          .map(customer => ({
            text: `${customer.fullName} (${customer.idCode})`,
            value: customer.id,
            role: customer.role
          })),
        ...this.systemCustomersForSelect
      ]
    }
  },

  watch: {
    'formData.channel': {
      handler: 'fillCustomerContact',
      immediate: true
    },
    'formData.languageId': 'onTemplateChange',
    'formData.scheduleId': 'onTemplateChange',
    messageTemplate: 'onTemplateChange'
  },

  validations: {
    messageTemplate: {},
    formData: {
      customerEmail: {},
      customerPhoneNumber: {},
      channel: {},
      languageId: {},
      recipients: {},
      infoSheetTemplateId: {},
      invoiceTemplateId: {},
      agreementTemplateId: {},
      scheduleTemplateId: {},
      templateId: {},
      files: {},
      scheduleId: {},
      documentCategoryId: {},
      subject: {
        required: validators.requiredUnless(function () {
          return /sms/i.test(this.formData.channel)
        })
      },
      text: {
        required: validators.required
      }
    }
  },

  methods: {
    ...mapActions('templates', ['loadTemplates', 'loadInvoices']),
    ...mapActions({
      loadTemplate (dispatch, payload) {
        return dispatch(this.storeModule + '/loadContractTemplate', payload)
      },
      loadMessageTemplate (dispatch, payload) {
        return dispatch(`${this.storeModule}/loadMessageTemplate`, payload)
      },
      sendMessage (dispatch, payload) {
        return dispatch(this.storeModule + '/sendContractMessage', payload)
      },
      findSystemCustomers (dispatch, payload) {
        return dispatch(`${this.storeModule}/findSystemCustomers`, payload)
      },
      createMessage (dispatch, payload) {
        return dispatch(this.storeModule + '/createMessage', payload)
      }
    }),
    ...mapMutations({
      setTemplate (commit, payload) {
        return commit(this.storeModule + '/SET_TEMPLATE', payload)
      }
    }),
    async previewInvoicePdf () {
      await this.loadTemplate({ templateId: this.formData.invoiceTemplateId, contractId: this.contract.id, languageId: this.formData.languageId, scheduleId: this.schedule?.id, invoiceDate: this.formData.invoiceDate })
    },
    setSelectedThirdParty (parties) {
      this.selectedThirdParty = this.thirdParties.filter(el => el.customerId === parties.id)
    },
    async resetData () {
      await this.loadTemplates()
      if (this.schedule) await this.loadInvoices({ scheduleId: this.schedule?.id })
      Object.assign(this.$data, this.$options.data.apply(this))
      this.setTemplate({ template: null })
      this.formData.languageId = this.classifierByName('languages', this.settingByKey('language'))?.id
      this.formData.documentCategoryId = this.classifierByName('DocumentCategoryType', 'OTHER')?.id
      if (this.isSendContract) {
        this.formData.agreementTemplateId = this.product.agreementTemplateId
        this.formData.infoSheetTemplateId = this.product.informationSheetTemplateId
        this.formData.scheduleTemplateId = this.product.scheduleTemplateId
        this.formData.scheduleId = this.schedule?.id
      }
      this.fillCustomerContact()
      this.$v.$reset()
    },
    onHide (e) {
      if (this.saving) e.preventDefault()
    },
    async onSubmit () {
      if (this.validate()) {
        if (this.selectedThirdParty) {
          this.formData.thirdPartyId = this.selectedThirdParty[0].id
        }
        if (!this.formData.scheduleId) {
          this.formData.scheduleId = this.schedule?.id
        }
        await this.sendMessage({ id: this.contract.id, data: this.formData, isSendContract: this.isSendContract })
        // await this.createMessage({ messageData: this.formData, customerId: this.thirdParties })
        this.$refs.modal.hide()
      }
    },
    async previewAgreementTemplatePdf () {
      this.loadTemplate({ templateId: this.formData.agreementTemplateId, contractId: this.contract.id, languageId: this.formData.languageId, scheduleId: this.formData.scheduleId, thirdPartyId: this.selectedThirdParty[0]?.id })
    },

    async previewInfosheetTemplatePdf () {
      this.loadTemplate({ templateId: this.formData.infoSheetTemplateId, contractId: this.contract.id, languageId: this.formData.languageId, scheduleId: this.formData.scheduleId })
    },

    async previewScheduleTemplatePdf () {
      this.loadTemplate({ templateId: this.formData.scheduleTemplateId, contractId: this.contract.id, languageId: this.formData.languageId, scheduleId: this.formData.scheduleId })
    },
    getMessageTemplate (templateId) {
      if (this.selectedThirdParty[0]?.id) {
        this.loadMessageTemplate({ templateId, contractId: this.contract.id, scheduleId: this.contract.scheduleId, thirdPartyId: this.selectedThirdParty[0].id })
      } else {
        this.loadMessageTemplate({ templateId, contractId: this.contract.id, scheduleId: this.contract.scheduleId })
      }
    },
    onTemplateChange () {
      const { subject = '', content = '' } = this.messageTemplate?.content[this.formData.languageId] || {}
      this.formData.subject = subject
      this.formData.text = content
    },
    fillCustomerContact () {
      const { emails = [], phoneNumbers = [] } = this.customer ?? {}
      if (this.isEmailChannel) {
        this.formData.customerEmail = findCustomerMainContact(emails)
        this.formData.customerPhoneNumber = null
      } else {
        this.formData.customerEmail = null
        this.formData.customerPhoneNumber = findCustomerMainContact(phoneNumbers)
      }
    }
  }
}
</script>


<i18n>
en:
  contractTitle:     "Send contract"
  notificationTitle: "Send notification"
  channel:           "Channel"
  language:          "Language"
  to:                "To"
  template:          "Template"
  subject:           "Subject"
  message:           "Message"
  selectedSchedule:  "Selected schedule"
  agreement:         "Agreement"
  schedule:          "Payment schedule"
  infoSheet:         "Info sheet"
  preview:           "Preview"
  attachments:       "Attachments"
  invoice:           "Invoice"
et:
  contractTitle:     "Send contract"
  notificationTitle: "Send notification"
  channel:           "Channel"
  language:          "Language"
  to:                "To"
  template:          "Template"
  subject:           "Subject"
  message:           "Message"
  selectedSchedule:  "Selected schedule"
  agreement:         "Agreement"
  schedule:          "Payment schedule"
  infoSheet:         "Info sheet"
  preview:           "Preview"
  attachments:       "Attachments"
  invoice:           "Invoice"
ru:
  contractTitle:     "Send contract"
  notificationTitle: "Send notification"
  channel:           "Channel"
  language:          "Language"
  to:                "To"
  template:          "Template"
  subject:           "Subject"
  message:           "Message"
  selectedSchedule:  "Selected schedule"
  agreement:         "Agreement"
  schedule:          "Payment schedule"
  infoSheet:         "Info sheet"
  preview:           "Preview"
  attachments:       "Attachments"
  invoice:           "Invoice"
</i18n>
